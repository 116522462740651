<template>
  <div id="app">
    <form
      method="post"
      action="https://www.uptodate.com/portal-login"
      name="uptodate"
      ref="form"
    >
      <input type="hidden" :value="d" name="portal" />
      <input type="hidden" :value="f" name="key" id="key" />
      <input type="submit" value="UpToDate" id="submitButton" />
    </form>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      h: 61209,
      g: 76864513,
      d: '502443_1ASP1',
      f: null,
    };
  },
  created() {
    let c = new Date();
    let f =
      this.b(c.getUTCMinutes()) +
      this.b(c.getUTCMonth()) +
      this.b(c.getUTCDate()) +
      this.b(c.getUTCHours());

    this.f = f * this.h - this.g;
  },
  mounted() {
    this.$refs.form.submit();
  },
  methods: {
    b(e) {
      return e < 10 ? '0' + e.toString() : e.toString();
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
